<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-9">
              <div class="form-group">
                <label for="type">Grupo</label>
                <select
                  class="custom-select"
                  name="type"
                  v-model="filter.group_id"
                >
                  <option value="">TODOS</option>
                  <option v-for="(g,idx) in groups" :key="idx" :value="g.id">{{ g.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="filter.status"
                >
                  <option value="">Todos</option>
                  <option value="99">Convidado</option>
                  <option value="0">A Definir</option>
                  <option value="1">Em Andamento</option>
                  <option value="2">Pendente</option>
                  <option value="3">Confirmado</option>
                  <option value="4">Declinado</option>
                  <option value="5">Inativo</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <label for="confirmation_date_start">Confirmação De</label>
                <date-picker
                  id="confirmation_date_start"
                  v-model="filter.confirmation_date_start"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="confirmation_date_end">Confirmação Até</label>
                <date-picker
                  id="confirmation_date_end"
                  v-model="filter.confirmation_date_end"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="tags">Tags</label>
                <multiselect tag-placeholder="Selecione uma ou mais tags" placeholder="Selecione uma ou mais tags" v-model="filter.tags" label="name" track-by="id" :options="tags" :multiple="true"></multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="city">Cidade</label>
                <input
                  id="city"
                  v-model="filter.city"
                  name="city"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <label for="district">Estado</label>
                <select
                  class="custom-select"
                  name="status"
                  v-model="filter.district"
                >
                  <option v-for="(s,i) in states" :key="i">{{ s.value }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="created_start">Inscrição De</label>
                <date-picker
                  id="created_end"
                  v-model="filter.created_start"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="created_end">Inscrição Até</label>
                <date-picker
                  id="created_end"
                  v-model="filter.created_end"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="school">Escola</label>
                <input
                  id="school"
                  v-model="filter.school"
                  name="school"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="csm">CSM</label>
                <input
                  id="csm"
                  v-model="filter.csm"
                  name="csm"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label for="food_restriction">Restrição Alimentar</label>
                <select
                  class="custom-select"
                  name="food_restriction"
                  v-model="filter.food_restriction"
                >
                  <option value="">Todos</option>
                  <option value="Intolerância à lactose">Intolerância à lactose</option>
                  <option value="Intolerância ao glúten">Intolerância ao glúten</option>
                  <option value="Alergia a frutos do mar">Alergia a frutos do mar</option>
                  <option value="Veganismo">Veganismo</option>
                  <option value="Vegetarismo">Vegetarismo</option>
                  <option value="Não tenho nenhuma restrição ou opção alimentar">Não tenho nenhuma restrição ou opção alimentar</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import { states } from '@/pages/participants/statesData'
import Multiselect from 'vue-multiselect'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'participant-filter',
  components: {
    DatePicker,
    Multiselect
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Participantes',
      selected: '',
      states: states,
      filter: {
        tags: []
      },
      modal: false
    }
  },
  mounted () {
    this.getTags()
    this.getGroups()
  },
  methods: {
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    ...mapActions('group', {
      getAllGroups: 'getAll'
    }),
    getGroups () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAllGroups(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    getTags (filter) {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    open () {
      this.modal = true
    },
    applyFilter () {
      if (typeof (this.selected) === 'object') {
        this.filter.district = this.selected.value
      }
      if (this.filter.tags.length > 0) {
        const pluck = (arr, key) => arr.map(i => i[key])
        this.filter.tagsIds = pluck(this.filter.tags, 'id')
      }
      this.$emit('participantFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {
        tags: []
      }
      this.selected = ''
    }
  },
  computed: {
    ...mapState('tag', ['tags', 'pagination']),
    ...mapState('group', ['groups'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.form-check-label { padding-top: 2px !important }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
