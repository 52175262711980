<template>
  <Layout>
    <participant-statistic ref="child"></participant-statistic>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-5">
                <div class="search-box mr-2 mb-2">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Pesquise por Nome, E-mail ou CPF e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                      <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                        <i class="mdi mdi-filter mr-1"></i> Pesquisar com filtros
                      </button>
                    </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="40%">Nome</th>
                    <th width="20%">Grupo</th>
                    <th width="10%">UF</th>
                    <th width="10%">Inscrição</th>
                    <th width="10%">Atualização</th>
                    <th width="20%">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in participants" :key="list.id" class="link" :class="{'inativado': parseInt(list.status) === 5}">
                    <td @click="editParticipant(list.id, list.event_companion, list.travel_companion)" >
                      <h5 class="font-size-14 mb-1" style="white-space: nowrap;text-overflow: ellipsis;max-width: 275px;overflow: hidden;">{{ list.name }}</h5>
                      <p class="text-muted mb-0">{{ list.email }}</p>
                    </td>
                    <td @click="editParticipant(list.id, list.event_companion, list.travel_companion)" >
                      <span class="badge badge-soft-secondary font-size-12" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 260px;overflow: hidden;">
                        {{ list.group ? list.group.data.name : 'SEM GRUPO' }}
                      </span>
                      <span class="badge font-size-12" :class="{'badge-soft-success': list.need_payment === 1 && list.payment_completed === 1, 'badge-soft-danger': list.need_payment === 1 && list.payment_completed === 0}" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 260px;overflow: hidden; display:inherit">
                        {{ list.need_payment === 1 && list.payment_completed === 1 ? 'Pagamento Confirmado' : list.need_payment === 1 && list.payment_completed === 0 ? 'Pagamento Pendente' : ''}}
                      </span>
                    </td>
                    <td @click="editParticipant(list.id, list.event_companion, list.travel_companion)" style="font-size: 12px !important">{{ list.state }}</td>
                    <td @click="editParticipant(list.id, list.event_companion, list.travel_companion)" style="font-size: 12px !important">{{ list.inscricao }}</td>
                    <td @click="editParticipant(list.id, list.event_companion, list.travel_companion)" style="font-size: 12px !important">{{ list.atualizacao }}</td>
                    <td>
                      <span
                        :class="statusClass[list.status]"
                        class="badge font-size-12 p-2" style="min-width: 120px">
                          {{ statusList[list.status] }}
                      </span>
                      <br />
                      <span
                        v-if="list.ref_status"
                        class="badge font-size-11 p-1 badge-secondary" style="min-width: 120px;margin-top:2px">
                          {{ refStatus[list.ref_status] }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="float-left">
                  Total de registros: <b>{{ totalRows }}</b>
                </div>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idParticipant="idParticipant" ref="formParticipant" @created="updateList"></modal-form>
    <companion-form v-model="modalFormC" :idParticipant="idParticipant" ref="formCompanion" @created="updateList"></companion-form>
    <modal-filter ref="modalFilter" @participantFiltered="getReport"></modal-filter>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import ModalForm from './Form'
import CompanionForm from './ModalForm'
import modalFilter from '@/components/modals/filters/ParticipantFilter'
import participantStatistic from '@/components/widgets/participant-statistic'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    participantStatistic,
    ModalForm,
    CompanionForm,
    modalFilter
  },
  name: 'Participantes',
  page: {
    title: 'Participantes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      timeout: 60000,
      search: '',
      statusClass: ['badge-dark', 'badge-primary', 'badge-warning', 'badge-success', 'badge-danger', 'badge-secondary'],
      refStatus: ['', 'Documentação', 'Contato telefônico', 'Emissão aéreo', 'Emissão transfer', 'Confirmação'],
      modalForm: false,
      modalFormC: false,
      modalDetail: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idParticipant: null,
      perPage: 20,
      submitted: false,
      title: 'Participantes',
      items: [{
        text: 'Participantes'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.timeout = setInterval(() => {
      this.updateList()
    }, 60000)
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
    this.GetStatus()
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  computed: {
    ...mapState('participant', ['participants', 'pagination']),
    ...mapState('settings', ['statusList']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('settings', { GetStatus: 'GetStatusList' }),
    ...mapActions('participant', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      changeModerator: 'changeModerator',
      deleteParticipant: 'deleteUser'
    }),
    cancelAutoUpdate () {
      clearInterval(this.timeout)
    },
    showFilters () {
      this.$refs.modalFilter.open()
    },
    newParticipant () {
      this.$refs.formParticipant.open('')
      this.modalForm = true
    },
    editParticipant (id, companionEvent, companionTravel) {
      if (!companionEvent && !companionTravel) {
        this.$refs.formParticipant.open(id)
        this.modalForm = true
        this.idParticipant = id
        return
      }
      this.$refs.formCompanion.open(id)
      this.modalFormC = true
      this.idParticipant = id
    },
    showDetailsIndication (userId) {
      this.modalDetail = true
      this.$refs.modalDetails.open(userId)
    },
    toggleStatus (participantId, statusOld, status) {
      Swal.fire({
        title: 'Você tem certeza?',
        html: `Mudar status de <b>${this.statusList[statusOld]}</b> para <b>${this.statusList[status]}</b>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#388741',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, MUDAR STATUS'
      }).then(result => {
        if (result.value) {
          try {
            this.changeStatus({ id: participantId, status }).then((res) => {
              successMessage('Sucesso: Status atualizado.', 2500)
            }).then(() => {
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    async toggleModerator (id) {
      try {
        await this.changeModerator(id)
        let message = 'Sucesso: Usuário atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteUser (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteParticipant(id).then((res) => {
              successMessage('Sucesso: Participante excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        include: 'group',
        type: filter.type || null,
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null,
        isOnline: filter.isOnline || null,
        need_payment: filter.need_payment || null,
        payment_completed: filter.payment_completed || null,
        city: filter.city || null,
        district: filter.district || null,
        created_start: filter.created_start ? this.$moment(filter.created_start).format('YYYY-MM-DD') : null,
        created_end: filter.created_end ? this.$moment(filter.created_end).format('YYYY-MM-DD') : null,
        confirmation_date_start: filter.confirmation_date_start ? this.$moment(filter.confirmation_date_start).format('YYYY-MM-DD') : null,
        confirmation_date_end: filter.confirmation_date_end ? this.$moment(filter.confirmation_date_end).format('YYYY-MM-DD') : null,
        aereo: filter.aereo || null,
        companion: filter.companion || null,
        csm: filter.csm || null,
        school: filter.school || null,
        food_restriction: filter.food_restriction || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
      // this.$refs.child.getSubscribers()
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.inativado { background: #f7ebeb }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
